<template>
    <v-dialog
        :value="value"
        :width="500"
        @input="$emit('input')"
    >
        <v-card>
            <bandeau-titre class="dialog-title">
                Sauvegarde du panier
            </bandeau-titre>
            <v-card-text class="dialog-body">
                <div class="row">
                    <v-col>
                        Sauvegarder un panier vous permet de le retrouver et le réutiliser à tout moment. Pour ce faire, veuillez saisir un nom sous lequel sauvegarder le panier.
                    </v-col>
                </div>
                <div class="row">
                    <v-col>
                        <v-text-field  label="Nom du panier" v-model="nomPanier"></v-text-field>
                    </v-col>
                </div>
            </v-card-text>
            <v-card-actions>
                <btn-theme @click="$emit('input', false)">
                    Annuler
                </btn-theme>
                <v-spacer/>
                <btn-theme :disabled="nomPanier.length === 0" @click="confirm">
                    Sauvegarder
                </btn-theme>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import BtnTheme from "../theme/BtnTheme";
    import BandeauTitre from "../theme/BandeauTitre";
    export default {
        name: 'SauvegardePanier',
        components: {
            BtnTheme,
            BandeauTitre
        },
        data: () => ({
            nomPanier: ''
        }),
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value (opened) {
                if (!opened) this.nomPanier = '';
            }
        },
        methods: {
            confirm () {
                this.$emit('confirm', this.nomPanier);
            }
        }
    }

</script>
<style>

</style>