<template>
    <div class="panier">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link :to="'/panier'">Panier</router-link>
            </div>
            <h1 class="titre">Votre panier</h1>
        </div>
        <div class="carte-arrondie">
            <div class="panier-contenu">
                <template v-if="panierEstVide">
                    <h3 class="text-center mx-auto my-6">
                        Votre panier est vide. Veuillez ajouter des éléments pour pouvoir passer commande.
                    </h3>
                </template>
                <template v-else>
                    <v-simple-table class="produits">
                        <thead>
                            <tr>
                                <th>Produit</th>
                                <th>Options</th>
                                <th>Qt.</th>
                                <th>P.U. HT</th>
                                <th>Prix total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(element, i) in panier" :key="`element-${i}`">
                                <td>
                                    <div class="identification">
                                        <div class="vignette">
                                            <template v-if="flipbookDisponible(element)">
                                                <v-hover>
                                                    <template v-slot:default="{ hover }">
                                                        <div>
                                                            <v-img-portail
                                                                :dist-url="elementVignetteUrl(element)"
                                                                :height="60"
                                                                :width="60"
                                                                contain
                                                                @click="flipbook(element)"
                                                                class="flipbook"
                                                            >
                                                                <v-overlay :opacity="0.8" absolute v-if="hover">
                                                                    <v-icon color="white">{{ mdiBookOpenPageVariant }}</v-icon>
                                                                </v-overlay>
                                                            </v-img-portail>
                                                        </div>
                                                    </template>
                                                </v-hover>
                                            </template>
                                            <template v-else>
                                                <v-img-portail
                                                    :dist-url="elementVignetteUrl(element)"
                                                    :height="60"
                                                    :width="60"
                                                    contain
                                                />
                                            </template>
                                        </div>
                                        <div class="titre">{{ element.titre }}</div>
                                    </div>
                                </td>
                                <td>
                                    {{ element.caracteristiques.map(el => el.libelle).join(', ') }}
                                </td>
                                <td>
                                    <v-text-field class="mt-0" style="width:55px" dense hide-details v-model="element.quantite" type="number" suffix="Ex."></v-text-field>
                                </td>
                                <td v-html="element.prix ? formatMonetaire(element.prix) : '-,--€'"></td>
                                <td>
                                    <template v-if="element.prix && element.quantite">{{ formatMonetaire(element.prix * element.quantite) }}</template>
                                    <template v-else>-,--€</template>
                                    <v-icon color="red" @click.stop="openDialog(element)">{{ mdiDelete }}</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <div class="complements">
                        <totalise-panier :key="recomputeReferencesNonPretes"></totalise-panier>
                        <btn-theme v-if="panierEstVide || referencesNonPretes.length > 0" disabled class="confirme">Calculs en cours ...</btn-theme>
                        <btn-theme v-else class="confirme" @click="confirmeCommande">Choisir un mode de livraison</btn-theme>
                    </div>
                </template>
                <v-dialog
                    v-model="deleteElementDialog"
                    :width="500"
                >
                    <v-card v-if="dialogElement">
                        <v-card-title class="text-break">
                            Confirmation de suppression
                        </v-card-title>
                        <v-card-text class="text-break">
                            Souhaitez-vous vraiment supprimer l'article "{{ dialogElement.titre }}" de votre panier ?
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <btn-theme @click="closeDialog">Non</btn-theme>
                            <v-spacer></v-spacer>
                            <btn-theme @click="supprimeElement(dialogElement)">Oui</btn-theme>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <sauvegarde-panier v-model="dialogueSauvegardePanier" @confirm="sauvegardePanier"></sauvegarde-panier>
                <restauration-panier v-model="dialogueRestaurationPanier"></restauration-panier>

                <v-snackbar
                    top
                    right
                    :timeout="-1"
                    :color="informationSauvegardePanier.type"
                    v-model="informationSauvegardePanier.ouvert"
                >
                    {{ informationSauvegardePanier.texte }}
                    <template v-slot:action="{ attrs }">
                        <v-btn text v-bind="attrs" @click="cacheInformation">
                            <v-icon>{{ mdiClose }}</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../api/api';
import RestaurationPanier from "../components/panier/RestaurationPanier";
import SauvegardePanier from "../components/panier/SauvegardePanier";
import MonnaieMixin from "../mixins/monnaie";
import ElementMixin from "../mixins/element";
import {mapGetters, mapActions} from "vuex";
import {empty} from "../helpers";
import BtnTheme from "../components/theme/BtnTheme";
import VImgPortail from "../components/theme/VImgPortail";
import TotalisePanier from "../components/TotalisePanier";
import { mdiBookOpenPageVariant, mdiDelete, mdiClose } from "@mdi/js";

export default {
    name: 'Panier',
    components: {
        TotalisePanier,
        BtnTheme,
        RestaurationPanier,
        SauvegardePanier,
        VImgPortail
    },
    mixins: [
        MonnaieMixin,
        ElementMixin
    ],
    data: () => ({
        urlActualiseElements:     process.env.VUE_APP_API_URL + '/boutique/elements',
        urlVignette:              process.env.VUE_APP_API_URL + '/image/vignette/grande',
        urlSauvegardePanier:      process.env.VUE_APP_API_URL + '/panier/sauve',
        deleteElementDialog: false,
        dialogElement: null,
        dialogueSauvegardePanier: false,
        dialogueRestaurationPanier: false,
        actualisationElementsNonPrets: null,
        recomputeReferencesNonPretes: 0,
        informationSauvegardePanier: {
            ouvert: false,
            type: '',
            texte: ''
        },
        debounceInputs: null,

        mdiBookOpenPageVariant,
        mdiDelete,
        mdiClose
    }),
    computed: {
        ...mapGetters([
            'panier',
            'isLogged',
            'portail'
        ]),
        panierEstVide () {
            return this.panier.length === 0;
        },
        referencesNonPretes() {
            // IMPORTANT: Ne surtout pas supprimer cette ligne, ça a beau etre un console.log,
            // celui-ci permet a l'interval de rafraichissement de forcer la recalculation
            // de cette computed (de par referencement d'une variable "dummy")
            // noinspection BadExpressionStatementJS
            this.recomputeReferencesNonPretes;
            return this.panier
                .filter(element => this.preparationNonTerminee(element))
                .map(element => element.reference);
        },
        quantitesPanierValide () {
            let valide = true;
            for (let i = 0; i < this.panier.length; i++) {
                if (!this.quantiteValide(this.panier[i])) valide = false;
            }
            return valide;
        },
    },
    watch: {
        panier: {
            handler (panier) {
                this.setPanier(panier);
                clearTimeout(this.debounceInputs)

                // delay new call 500ms
                this.debounceInputs = setTimeout(() => {
                    for (let i = 0; i < this.panier.length; i++) {
                        if (!this.quantiteValide(this.panier[i])) this.setQuantiteValideLaPlusProche(this.panier[i]);
                    }
                }, 500)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([
            'setPanier'
        ]),
        elementVignetteUrl (element) {
            let vignette = element.vignette || 'nopicture.jpg';
            return this.urlVignette + '/' + encodeURIComponent(vignette);
        },
        changeLot (element) {
            let idx = this.panier.findIndex(el => element.id === el.id);

            this.$set(this.panier[idx], 'prix'     , element.prix_lot[element.lot]);
            this.$set(this.panier[idx], 'lot'      , Number(element.lot));
            this.$set(this.panier[idx], 'poids'    , element.lot * element.poidsunitaire);
            this.$set(this.panier[idx], 'epaisseur', element.lot * element.epaisseurunitaire);
        },
        quantiteValide (element) {
            return element.quantite >= this.quantiteMinimaleCalculee(element)
                && element.quantite <= element.disponibilite
                && !(element.quantite_maximale && element.quantite > element.quantite_maximale);
        },
        setQuantiteValideLaPlusProche(element) {
            element.quantite = Math.max(Math.min(element.quantite_maximale || element.disponibilite, element.quantite), element.quantite_minimale);
        },
        supprimeElement (element) {
            let panier = Object.assign([], this.panier);
            let index = this.panier.findIndex(el => el.id === element.id);
            panier.splice(index, 1);
            this.setPanier(panier);
            this.closeDialog();
        },
        openDialog (element) {
            this.dialogElement = element;
            this.deleteElementDialog = true;
        },
        closeDialog () {
            this.deleteElementDialog = false;
            this.dialogElement = null;
        },
        afficheDialogueSauvegardePanier () {
            this.dialogueSauvegardePanier = true;
        },
        cacheDialogueSauvegardePanier () {
            this.dialogueSauvegardePanier = false;
            this.nomPanier = '';
        },
        afficheDialogueRestaurationPanier () {
            this.dialogueRestaurationPanier = true;
        },
        cacheDialogueRestaurationPanier () {
            this.dialogueRestaurationPanier = false;
        },
        sauvegardePanier (nomPanier) {
            let data = new FormData();
            data.append('nom', nomPanier);
            data.append('panier', JSON.stringify(this.panier));

            Api.post(this.urlSauvegardePanier, data)
                .then(() => {
                    this.ouvreInformation(`Votre panier a bien été sauvegardé sous le nom "${nomPanier}"`, 'success');
                    this.$nextTick(() => {
                        this.cacheDialogueSauvegardePanier();
                    })
                })
                .catch(() => {
                    this.ouvreInformation('Une erreur est survenue durant la sauvegarde de votre panier. Si cela se reproduit, merci de contacter le support.', 'error');
                });
        },
        ouvreInformation (texte, type) {
            this.informationSauvegardePanier = Object.assign(this.informationSauvegardePanier, {
                ouvert: true,
                type: type,
                texte: texte
            });
        },
        cacheInformation () {
            this.informationSauvegardePanier = Object.assign(this.informationSauvegardePanier, {
                ouvert: false
            });
        },
        confirmeCommande () {
            // Note de VS:
            // Au lieu de mettre une alerte qui bloque les scripts ou un dialogue html qui bloque
            // la vue et les contrôles temporairement, il est préférable de désactiver le bouton
            // de confirmation (pas de message d'indication n'est mis pour l'instant)
            //if (this.isLogged === false)
            //    this.$store.commit('sessionExpired');
            //else
            this.$router.push({ path: '/panier/valider' });
        },
        actualiseElementsNonPrets () {
            // Si on est sur un panier vide, il est possible que ce soit qu'il n'est pas encore chargé
            // C'est alors mieux d'avoir une fonction qui ne fait rien toutes les 5s qu'un panier qui ne s'actualise plus
            if (this.panier.length === 0) return;
            if (this.referencesNonPretes.length === 0) {
                clearInterval(this.actualisationElementsNonPrets);
                return;
            }
            let data = new FormData();
            for(let i = 0; i < this.referencesNonPretes.length; i++) data.append('references[]',this.referencesNonPretes[i]);
            Api.post(this.urlActualiseElements, data).then(liste => {
                //on fait une copie locale du panier que l'on pouvoir modifier
                let panier = this.panier;
                let panierJson = JSON.stringify(panier); //pour comparaison à la fin de cette routine
                for (let i = 0; i < liste.length; i++) { //on passe en revu les élements retournés
                    let element = liste[i];
                    let idx = panier.findIndex(el => element.id === el.id);
                    let elementPanier = panier[idx];
                    //si le status n'a pas changer, on passe à la suite
                    if (element.etat_preparation === elementPanier.etat_preparation) continue;

                    //on récupère la quantité et le lot du panier afin de garder les changements utilisateur
                    let quantite = elementPanier.quantite;
                    let lot = elementPanier.lot !== undefined ? elementPanier.lot : null;
                    //on récupère aussi les données de personnalisation "à la volée" si elles existent
                    let contenu = elementPanier.contenu !== undefined ? elementPanier.contenu : null;
                    //si le lot préselectionné n'existe plus ou est incorrect on prend celui qui viens d'être chargé
                    if (lot === null) lot = element.lot; //si chargement initial, on prend celui de l'élement chargé

                    element.poids = lot * element.poidsunitaire;
                    if (lot && lot > 1) {
                        element.lot = lot;
                        if (element.prix_lot && typeof element.prix_lot[lot] !== 'undefined' && !empty(element.prix_lot[lot])) {
                            element.prix = element.prix_lot[lot];
                        }
                    }
                    if (isNaN(quantite) || quantite < this.quantiteMinimaleCalculee(element)) element.quantite = this.quantiteMinimaleCalculee(element);
                    element.quantite = quantite;
                    if (contenu) element.contenu = contenu;
                    panier[idx] = Object.assign({}, element);
                }
                //à la fin de la boucle, si au moins un élément du panier a changé, on remonte son état dans storex
                if (JSON.stringify(panier) !== panierJson) {
                    this.setPanier(panier);
                    this.recomputeReferencesNonPretes++;
                }
            });
        },
        flipbook(element) {
            if (this.flipbookDisponible(element)) this.$router.push('/liseuse/' + element.id);
        }
    },
    beforeDestroy() {
        clearInterval(this.actualisationElementsNonPrets);
    },
    mounted() {
        // Si on a des références non prêtes, on lance l'actualisation de celles-ci toutes les 5s
        this.actualisationElementsNonPrets = setInterval(this.actualiseElementsNonPrets.bind(this), 5000);
    }
}

</script>
<style scoped lang="scss">

.panier {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    & .scrollable-elements {
        //overflow-y: auto;
    }
    & .panier-contenu {
        display:flex;
        width:100%;
        & .complements {
            flex-grow:1;
            max-width:360px;
            margin: auto auto;

            & .confirme {
                width:Calc(100% - 80px);
                margin: 10px 40px 0 40px;
            }
        }
        & .produits {
            & th {
                color:black;
                font: normal normal normal 20px/29px Nunito;
            }
            & tbody {
                & > tr > td:first-child {
                    color: #535353;
                }
                & tr {
                    & > td {
                        padding: 4px 8px;
                        font: normal normal normal 15px/22px Nunito;
                        &:not(:last-child) {
                            border-right:rgba(0, 0, 0, 0.2) 1px solid;
                        }
                        &:last-child {
                            position:relative;
                            & > .v-icon {
                                position:absolute;
                                right:10px;
                            }
                        }
                        & .identification {
                            display:flex;
                            & .titre {
                                margin:auto 0 auto 3px;
                            }
                            & .vignette {
                                .v-overlay {
                                    border-radius:5px;
                                }
                                cursor:pointer;
                            }
                        }
                    }
                }
                color:black;
            }
        }
        & .cadre-vignette {
            flex-direction: column;
            align-items: center;
            flex-basis: auto;
            flex-grow: 0;
            & .flipbook {
                cursor: pointer;
            }

        }
    }
}
.contenu {
}

.v-input.align-right {
    input {
        text-align: right;
    }
}

.error-text {
    color: red;
}
.font-size-11 {
    font-size: 1.1em;
}
.font-size-09 {
    font-size: 0.9em;
}
</style>

<style lang="scss">

.produits .v-input {
    /* Chrome, Safari, Edge, Opera */
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type=number] {
        -moz-appearance: textfield;
    }
}
</style>