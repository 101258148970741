<template>
    <v-dialog
        :value="value"
        :width="500"
        @input="$emit('input')"
    >
        <v-card :loading="paniersSauvegardesEnChargement">
            <bandeau-titre class="dialog-title">
                Paniers sauvegardés
            </bandeau-titre>
            <v-card-text class="dialog-body">
                <div class="selection-panier-instructions">
                    Veuillez sélectionner un des paniers sauvegardés pour le réutiliser
                </div>
                <div class="liste-paniers-sauvegardes">
                    <template v-for="(panierSauvegarde, index) in paniersSauvegardes">
                        <div class="panier-sauvegarde" :class="{ selectionne: index === indexPanierSelectionne }" :key="index" @click="selectionnePanier(index)">
                            <div class="panier-sauvegarde-titre">
                                {{ panierSauvegarde.nom }}
                            </div>
                            <div class="panier-sauvegarde-contenu">
                                {{ panierSauvegarde.contenu.length }} éléments dans ce panier
                            </div>
                            <div class="panier-sauvegarde-suppression">
                                <btn-theme small class="panier-sauvegarde-suppression-btn" @click="supprimePanier(index)">
                                    <v-icon>{{ mdiClose }}</v-icon>
                                </btn-theme>
                            </div>
                        </div>
                    </template>
                </div>
            </v-card-text>
            <v-card-actions>
                <btn-theme @click="$emit('input', false)">
                    Annuler
                </btn-theme>
                <v-spacer/>
                <btn-theme :disabled="indexPanierSelectionne === null" @click="chargeSelectionPanier">
                    Charger
                </btn-theme>
            </v-card-actions>
        </v-card>

        <v-snackbar
            top
            right
            :timeout="-1"
            :color="informationRequete.status"
            v-model="informationRequete.ouvert"
        >
            <div v-html="informationRequete.texte"></div>

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="fermeInformationRequete"
                >Fermer</v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import Api from "../../api/api";
import BandeauTitre from "../theme/BandeauTitre";
import BtnTheme from "../theme/BtnTheme";
import {mapActions, mapGetters} from "vuex";
import { mdiClose } from "@mdi/js";

export default {
    name: 'RestaurationPanier',
    components: {
        BandeauTitre,
        BtnTheme
    },
    data: () => ({
        urlListePaniersSauvegardes: process.env.VUE_APP_API_URL + '/panier/liste',
        urlSuppressionPanierSauvegarde: process.env.VUE_APP_API_URL + '/panier/efface',
        paniersSauvegardesEnChargement: false,
        paniersSauvegardes: [],
        indexPanierSelectionne: null,
        informationRequete: {
            ouvert: false,
            texte: '',
            status: '',
        },
        mdiClose
    }),
    props: {
        value: {
            type: Boolean,
            value: false
        }
    },
    watch: {
        value (opened) {
            if (opened) this.recupereListePaniersSauvegardes();
            if (!opened) this.indexPanierSelectionne = null;
        }
    },
    computed: {
        ...mapGetters([
            'isLogged'
        ])
    },
    methods: {
        ...mapActions([
            'setPanier'
        ]),
        recupereListePaniersSauvegardes () {
            this.paniersSauvegardesEnChargement = true;
            if (this.isLogged) { // On ne sauvegarde des paniers que si on est connecté
                Api.get(this.urlListePaniersSauvegardes)
                    .then(paniersSauvegardes => {
                        this.paniersSauvegardes = Object.assign([], paniersSauvegardes);
                        this.paniersSauvegardesEnChargement = false;
                    })
                    .catch(() => {
                        this.$emit('error','Une erreur est survenue durant la récupération des paniers sauvegardés. Si cela se reproduit, merci de contacter le support.');
                        this.paniersSauvegardesEnChargement = false;
                    })
            }
        },
        selectionnePanier (index) {
            this.indexPanierSelectionne = index;
        },
        chargeSelectionPanier () {
            let panierSelectionne = Object.assign({}, this.paniersSauvegardes[this.indexPanierSelectionne]);
            this.setPanier(panierSelectionne.contenu);
            this.$emit('input', false);
        },
        supprimePanier (index) {
            let panier = Object.assign({}, this.paniersSauvegardes[index]);

            if (confirm("Êtes-vous sûr de vouloir supprimer ce panier ?")) {
                let data = new FormData();
                data.append('id', panier.id);
                Api.post(this.urlSuppressionPanierSauvegarde, panier)
                    .then(() => {
                        this.selectionnePanier(null);
                        this.recupereListePaniersSauvegardes();
                        this.ouvreInformationRequete("Le panier sauvegardé a bien été supprimé.", 'success');
                    })
                    .catch(() => {
                        this.ouvreInformationRequete("Une erreur est survenue durant la suppression. Veuillez réessayer plus tard ou contacter le support.");
                    });
            }
        },
        ouvreInformationRequete (texte, status) {
            this.informationRequete = Object.assign(this.informationRequete, { ouvert: true, texte, status });
        },
        fermeInformationRequete () {
            this.informationRequete.ouvert = false;
        }
    },
    mounted () {
        // 04/05/21 Adrien : Les paniers sont déjà chargés à l'ouverture du composant, ce double appel ne semble pas nécessaire
        //this.recupereListePaniersSauvegardes();
    }
}
</script>

<style lang="scss" scoped>
    .selection-panier-instructions {
        font-weight: bold;
        margin: 0.4em 0;
    }
    .liste-paniers-sauvegardes {
        & .panier-sauvegarde {
            display: flex;
            flex-direction: row;
            padding: 1em 1.5em;
            border: 1px solid lightgray;
            border-top:none;
            justify-content: space-between;

            &:first-child {
                border-top: 1px solid lightgray;
            }

            &:hover {
                background-color: #f0f0f0;
            }

            &.selectionne {
                background-color: #d0d0d0;
            }

            & .panier-sauvegarde-titre {
                font-weight: bold;
            }

            & .panier-sauvegarde-suppression {
                & .panier-sauvegarde-suppression-btn {
                    min-width: unset;
                }
            }
        }
    }
</style>