<template>
    <div class="recap">
        <h2>Total de votre panier</h2>
        <table>
            <tr>
                <td>Total Produits Hors Taxes</td>
                <td>{{ formatMonetaire(totalProduits) }}</td>
            </tr>
            <tr>
                <td>Frais de Gestion Hors Taxes</td>
                <td>{{ formatMonetaire(fraisLogistique + fraisEmballages) }}</td>
            </tr>
            <tr>
                <td>Frais de livraison Hors Taxes</td>
                <td>{{ formatMonetaire(tarifTransporteur) }}</td>
            </tr>
            <tr  v-if="reduction">
                <td>Réduction </td>
                <td><template v-if="this.promotion">- </template>{{ formatMonetaire(totalPromotion) }}</td>
            </tr>
            <tr>
                <td>TVA</td>
                <td>{{ formatMonetaire(valeurTva) }}</td>
            </tr>
            <tr>
                <td>Prix TTC</td>
                <td>{{ formatMonetaire(totalPanierHT + valeurTva) }}</td>
            </tr>
        </table>
    </div>
</template>
<script>

import MonnaieMixin from "../mixins/monnaie";
import ElementMixin from "../mixins/element";
import Api from "../api/api";
import {mapGetters} from "vuex";

export default {
    name: "TotalisePanier",
    mixins: [
        MonnaieMixin,
        ElementMixin
    ],
    components: {

    },
    data () {
        return {
            urlEstimerFraisCommande:  process.env.VUE_APP_API_URL + '/envoi/calculfrais',
            fraisEmballages: 0,
            fraisLogistique: 0,
            reduction: null,
            livraison: null
        }
    },
    props: {
        tarifTransporteur: {
            type: Number,
            default: null
        },
        promotion: {
            type: Object,
            default: null
        }
    },
    watch: {
        panier: {
            handler () {
                this.actualiseEstimationFrais();
            },
            deep: true
        },
    },
    computed: {
        ...mapGetters([
            'panier'
        ]),
        referencesNonPretes() {
            // IMPORTANT: Ne surtout pas supprimer cette ligne, ça a beau etre un console.log,
            // celui-ci permet a l'interval de rafraichissement de forcer la recalculation
            // de cette computed (de par referencement d'une variable "dummy")
            // noinspection BadExpressionStatementJS
            this.recomputeReferencesNonPretes;
            return this.panier
                .filter(element => this.preparationNonTerminee(element))
                .map(element => element.reference);
        },
        prixTotalPanier () {
            return this.arrondiMonetaire(this.panier.reduce((sum, element) => {
                return sum + (element.quantite * this.prixUnitaireCalcule(element));
            }, 0), (this.portail ? this.portail.decimales_prix : 2));
        },
        poidsTotalPanier() {
            return Math.round(this.panier.reduce((sum, element) => {
                return sum + (element.quantite * element.poidsunitaire);
            }, 0));
        },
        totalProduits () {
            return this.panier.map(el => el.prix * el.quantite).reduce((p, a) => p + a);
        },
        valeurTva() {
            return (this.totalPanierHT) * 0.2;
        },
        PanierHT() {
            return this.fraisLogistique + this.fraisEmballages + this.totalProduits + (this.tarifTransporteur || 0);
        },
        totalPanierHT() {
            return this.PanierHT - this.totalPromotion;
        },
        totalPromotion() {
            if (!this.promotion) return null;
            else if(this.promotion.type === '%') return this.PanierHT * (this.promotion.valeur / 100);
            else return this.promotion.valeur;
        }
    },
    methods: {
        actualiseEstimationFrais() {
            if (this.poidsTotalPanier > 0) {
                Api.post(this.urlEstimerFraisCommande, {poids: this.poidsTotalPanier})
                    .then((donnees) => {
                        this.fraisEmballages = donnees.prixemballages;
                        this.fraisLogistique = donnees.logistique;
                    })
            }
        },
    },
    mounted () {
        this.actualiseEstimationFrais();
    }
}

</script>
<style scoped lang="scss">

.recap {
    text-align:center;
    background-color: #F5F5F5;
    border: 1px solid #939393;
    font-family: Nunito;
    border-radius: 20px;
    min-width:360px;
    & > table {
        $margin: 12px;
        width: Calc(100% - #{2 * $margin});
        margin: 0 $margin;
        &  > tr {
            &:last-child {
                font-weight: 1000;
            }
            & > td {
                &:last-child {
                    text-align: right;
                }
                &:first-child {
                    text-align:left;
                }
            }
        }
    }
}
</style>